<template>
  <div class="container">
    <img :src="projectState.src" width="100%" :alt="projectState.text" />
  </div>
</template>

<script>
export default {
  name: 'ActivityStatus',
  data() {
    return {
      projectStateMap: {
        0: {
          text: '活动未开始',
          src: require('@/assets/activity-status/activity-not-start.png')
        },
        2: {
          text: '活动已结束',
          src: require('@/assets/activity-status/activity-end.png')
        },
        3: {
          text: '活动不存在'
        },
        4: {
          text: '活动状态未知'
        }
      }
    }
  },
  computed: {
    projectState() {
      return {
        ...this.projectStateMap[this.$route.query.status]
      }
    }
  }
}
</script>

<style lang="less" scoped></style>
